import { Text } from '@codecademy/gamut';
import React from 'react';

import { LoginForm } from '~/components/Forms/LoginForm';
import { LinkWithRedirect } from '~/components/LinkWithRedirect';
import { PaymentModal } from '~/components/PaymentModal';
import { CorporateContainer } from '~/corporate/layouts/CorporateContainer';
import { CorporateFormPage } from '~/corporate/layouts/CorporateFormPage';

interface LoginCorporatePage {
  redirectUrl?: string;
}

export interface LoginPageProps {
  corporatePage?: LoginCorporatePage;
}

export const LoginPage: React.FC<React.PropsWithChildren<LoginPageProps>> = ({
  corporatePage: { redirectUrl } = {},
}) => {
  return (
    <CorporateContainer pageMetaProps={{ title: 'Log in' }}>
      <CorporateFormPage title="Log in to Codecademy">
        <LoginForm redirectUrl={redirectUrl} />
        <Text whiteSpace="break-spaces">Not a member yet? </Text>
        <LinkWithRedirect href="/register" redirectUrl={redirectUrl}>
          <b>Sign up for free</b>
        </LinkWithRedirect>
      </CorporateFormPage>
      <PaymentModal />
    </CorporateContainer>
  );
};

export default LoginPage;
