import { FillButton, FlexBox, Modal, Text } from '@codecademy/gamut';
import { CodeCelebration, NumberBlocks } from '@codecademy/gamut-illustrations';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLocationQuery } from '~/state/location/selectors';

type View =
  | 'paid'
  | 'earlyrenewal'
  | 'none'
  | 'uncollectible'
  | 'order_voided_paid'
  | 'order_voided_uncollectible';

interface ISubscriptionDetails {
  planType: string;
  planInterval: string;
  periodEnd: Date;
}

export const PaymentModal: React.FC = () => {
  const [view, setView] = useState<View>('none');
  const [
    subscriptionDetails,
    setSubscriptionDetails,
  ] = useState<ISubscriptionDetails>({
    planType: 'Pro',
    planInterval: 'annual',
    periodEnd: new Date(),
  });

  const query = useSelector(selectLocationQuery);

  useEffect(() => {
    try {
      const initialView = query?.paymentModalType?.toString();

      if (initialView === 'paid' && !!query?.renewalPeriodEnd) {
        setSubscriptionDetails({
          planType: query?.renewalPlanType?.toString(),
          planInterval: query?.renewalPlanInterval?.toString(),
          periodEnd: new Date(query?.renewalPeriodEnd?.toString()),
        });
      }

      if (initialView) {
        setView(initialView as View);
        // Clear all the query params from the URL
        window.history.replaceState(
          {},
          window.document.title,
          window.location.pathname
        );
      }
    } catch {
      setView('none');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setView('none');
  };

  const Copy = () => {
    switch (view) {
      case 'paid':
        const { planType, planInterval, periodEnd } = subscriptionDetails;
        const formattedPeriodEnd = format(periodEnd, 'MMMM d, yyyy');
        return (
          <Text variant="title-sm" mt={8} textAlign="center">
            {`Your ${planType} ${planInterval} subscription will extend until ${formattedPeriodEnd}.`}
          </Text>
        );
      case 'uncollectible':
        return (
          <Text variant="p-large" mt={8} textAlign="center">
            You are now on the Basic plan. Please upgrade if you would like to
            continue your subscription.
          </Text>
        );
      case 'order_voided_paid':
        return (
          <Text variant="p-large" mt={8} textAlign="center">
            There was an issue processing your purchase. If any amount was
            deducted, rest assured you will be refunded.
          </Text>
        );
      case 'order_voided_uncollectible':
        return (
          <>
            <Text variant="p-large" mt={8} textAlign="center">
              There was an issue processing your purchase. If any amount was
              deducted, rest assured you will be refunded.
            </Text>
            <Text variant="p-large" mt={4} textAlign="center">
              You are now on the Basic plan. Please upgrade if you would like to
              continue your subscription.
            </Text>
          </>
        );
      default:
        return null;
    }
  };

  const Illustration = () => {
    switch (view) {
      case 'paid':
        return <CodeCelebration width={195} height="auto" aria-hidden />;
      default:
        return <NumberBlocks width={195} height="auto" aria-hidden />;
    }
  };

  const getTitle = () => {
    switch (view) {
      case 'paid':
        return 'Success!';
      case 'uncollectible':
        return 'Renewal payment not processed';
      default:
        return 'Payment not processed';
    }
  };

  const CTA = () => {
    switch (view) {
      case 'paid':
        return (
          <FillButton onClick={handleClose} mt={24}>
            Start learning
          </FillButton>
        );
      default:
        return null;
    }
  };

  return (
    <Modal onRequestClose={handleClose} isOpen={view !== 'none'}>
      <FlexBox flexDirection="column" alignItems="center" p={24}>
        <Illustration />
        <Text variant="title-md" mt={24}>
          {getTitle()}
        </Text>
        <Copy />
        <CTA />
      </FlexBox>
    </Modal>
  );
};
